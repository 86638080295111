import React from 'react';
import Image from 'next/image';
import usePrefix from '@/hooks/usePrefix';
import { CDN2 } from '@/helpers/api';
import { pushLayerEvent } from '@/utils/gtag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { InstagramOutlined, LinkedinFilled } from './Icons';

export interface IFeatures {
  width?: number;
  height?: number;
}

export default function ButtonSocial({ ...props }: IFeatures) {
  const handleIos = () => {
    pushLayerEvent('nosonOpenIosStore', {});
  };

  const prefix = usePrefix();
  return (
    <div className="flex flex-row gap-x-2">
      <a
        href="https://youtube.com/@onairparking?si=4jONwtQomK97Pyy_"
        target="_blank"
        rel="noopener noreferrer"
        className="flex justify-center items-center hover:scale-110 w-8 h-8 rounded-full bg-black"
      >
        <Image
          src={CDN2() + '/staticmyapp/youtube.svg'}
          width={20}
          height={20}
          alt="Tiktok icon"
        />
      </a>
      <a
        href="https://www.instagram.com/onairparking/"
        target="_blank"
        rel="noopener noreferrer"
        className="flex justify-center items-center hover:scale-110 w-8 h-8 rounded-full bg-black"
      >
        <InstagramOutlined style={{ fontSize: 20 }} className="text-white" />
      </a>
      <a
        href="https://www.facebook.com/ParkOnAir"
        target="_blank"
        rel="noopener noreferrer"
        className="flex justify-center items-center hover:scale-110 w-8 h-8 rounded-full bg-black"
      >
        <FontAwesomeIcon
          icon={faFacebookF}
          className="text-white "
          style={{ width: 12 }}
        />
      </a>
      <a
        href="https://www.linkedin.com/company/onairparking/posts/?feedView=all"
        target="_blank"
        rel="noopener noreferrer"
        className="flex justify-center items-center hover:scale-110 w-8 h-8 rounded-full bg-black"
      >
        <LinkedinFilled
          style={{ fontSize: 18 }}
          color="primary"
          className="text-white"
        />
      </a>
      <a
        href="https://www.tiktok.com/@onairparking"
        target="_blank"
        rel="noopener noreferrer"
        className="flex justify-center items-center hover:scale-110 w-8 h-8 rounded-full bg-black"
      >
        <Image
          src={CDN2() + '/staticmyapp/tik-tok.svg'}
          width={20}
          height={20}
          alt="Tiktok icon"
        />
      </a>
      <a
        href="https://twitter.com/onairparking"
        target="_blank"
        rel="noopener noreferrer"
        className="flex justify-center items-center hover:scale-110 w-8 h-8 rounded-full bg-black"
      >
        <Image
          src={CDN2() + '/staticmyapp/x-twitter.svg'}
          width={20}
          height={20}
          alt="X Icon"
        />
      </a>
    </div>
  );
}
